<template>
  <div>
    <div class="company" v-if="enterpriseType">
      <div class="company_left">
        <upload-image
          :imageUrl.sync="enterpriseInfo.companyLogoLook"
          :saveUrl.sync="enterpriseInfo.companyLogo"
        />
        <el-form
          :model="enterpriseInfo"
          :inline="true"
          :rules="rulesCompanyInfo"
          ref="rulesCompanyInfo"
          class="companyInfo"
        >
          <div class="info">
            <div>
              <div class="fullName" v-if="!isShowInput">
                <div class="companyFullName">
                  {{ enterpriseInfo.companyFullName }}
                </div>
                <i class="jr-iconjr-icon-edit1" @click="editClick" />
              </div>
              <div class="fullName" v-else style="margin-bottom: 10px">
                <el-form-item prop="companyFullName">
                  <el-input v-model="enterpriseInfo.companyFullName" />
                </el-form-item>
                <el-button type="primary" @click="sureInfo">保 存</el-button>
              </div>
            </div>
            <div class="updateTime">
              更新时间：{{ enterpriseInfo.updateTime }}
            </div>
          </div>
        </el-form>
      </div>
      <div class="company_right">
        <div>创建人：{{ enterpriseInfo.createName }}</div>
        <div>修改人：{{ enterpriseInfo.updateName }}</div>
        <div>收录时间：{{ enterpriseInfo.createTime }}</div>
      </div>
    </div>
    <el-form
      v-else
      :model="enterpriseInfo"
      :inline="true"
      :rules="rulesCompanyInfo"
      ref="rulesCompanyInfo"
      class="companyInfo"
    >
      <el-form-item class="avatar">
        <upload-image :saveUrl.sync="enterpriseInfo.companyLogo" />
      </el-form-item>
      <el-form-item label="公司全称：" prop="companyFullName">
        <el-input
          v-model="enterpriseInfo.companyFullName"
          class="lager-input"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="saveCompanyInfo">保存</el-button>
      </el-form-item>-->
    </el-form>
    <div class="editEnterprise" ref="editEnterprise">
      <el-tabs v-model="activeName" class="ly_tabs" @tab-click="selectTabs">
        <el-tab-pane label="基本信息" name="first">
          <basic-info
            ref="basicInfo"
            :enterpriseInfo.sync="enterpriseInfo"
            :enterpriseType="enterpriseType"
            @inputChange="inputChange"
            @inputEmailChange="inputEmailChange"
          />
        </el-tab-pane>
        <el-tab-pane label="企业评估" name="second">
          <evaluation
            ref="enterpriseInfo"
            :enterpriseInfo.sync="enterpriseInfo"
            :enterpriseType="enterpriseType"
          />
        </el-tab-pane>
        <el-tab-pane label="联系信息" name="third">
          <contact-info
            ref="contactInfo"
            :enterpriseInfo.sync="enterpriseInfo"
            :enterpriseType="enterpriseType"
          />
        </el-tab-pane>
        <el-tab-pane label="团队信息" name="fourth">
          <team-info
            ref="teamInfo"
            :teamInfoTable.sync="teamInfoTable"
            :enterpriseInfo.sync="enterpriseInfo"
            :enterpriseType="enterpriseType"
            :companyId="companyId"
            @addTeamInfo="addTeamInfo"
            @updateTeamInfo="updateTeamInfo"
            @deleteTeamInfo="deleteTeamInfo"
          />
        </el-tab-pane>
        <el-tab-pane label="标签信息" name="five">
          <label-info
            ref="labelInfo"
            :enterpriseType="enterpriseType"
            :companyId="companyId"
          />
        </el-tab-pane>
        <el-tab-pane label="半自动化标签" name="six">
          <robotizationlabel
            ref="robotizationlabel"
            :activeId="activeId"
            v-show="headTabActive == 2"
            :companyId="companyId"
            :type="type"
            :companyCode="companyCode"
          ></robotizationlabel>
        </el-tab-pane>
      </el-tabs>
      <div class="bottom" v-if="this.activeName != 'six'">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateCompany,
  addCompany,
  getCompanyById,
  insertCompanyLabel,
  updateCompanyLabel,
  selectCompanyLabel,
  updateMember,
  getMemberById,
} from "@/api/enterprise";
import UploadImage from "@/components/UploadImage.vue";
import BasicInfo from "./BasicInfo";
import ContactInfo from "./ContactInfo";
import TeamInfo from "./TeamInfo";
import Evaluation from "./Evaluation";
import LabelInfo from "./LabelInfo";
import robotizationlabel from "./robotizationlabel.vue";
const defaultEnterpriseInfo = Object.freeze({
  companyLogo: "", // 公司logo
  companyFullName: "", // 公司全称
  companyOperationalStatus: "", // 运营状态
  companyName: "", // 公司名称
  companyShortName: "", // 公司简称
  companyWebsite: "", // 公司网址
  companyEstablishTime: "", // 成立时间
  companyDescribe: "", // 一句话介绍
  companyBriefIntroduction: "", // 公司简介
  socialSecurityNumber: "", // 社保人数
  industrialChainId: [], // 产业类别
  techId: [], // 技术类别
  techChildrenId: [], // 技术类别子标签
  fieldId: [], // 领域类别
  fieldChildrenId: [], // 领域类别子标签
  cooperationStatus: "", // 合作状态
  companyFinancingRounds: "", // 融资轮次
  isQianlima: "", // 千里马公司
  isUnicorn: "", // 独角兽公司
  companyCountry: "", // 国家
  companyProvince: "", // 省份
  companyCity: "", // 城市
  companyScope: "",
  companyLegalPerson: "", // 企业法人
  companyTelephones: [], // 企业电话
  companyEmails: [], // 企业邮箱
  companyAddress: "", // 企业地址
  companySize: "", // 企业规模
  industryCoverNum: null, // 产业覆盖行业数
  serviceCompanyNum: null, // 服务企业数
  productCaseNum: null, // 产品案例数

  companyArr1: null,
});
export default {
  name: "EnterpriseInfo",
  components: {
    BasicInfo,
    ContactInfo,
    TeamInfo,
    UploadImage,
    Evaluation,
    LabelInfo,
    robotizationlabel,
  },
  data() {
    return {
      activeName: "first",
      basicInfo: 0,
      enterpriseInfoPage: 0,
      contactInfo: 0,
      teamInfo: 0,
      labelInfo: 0,
      isShowInput: false,
      searchBarFixed: false,
      saveCompanyLogo: "",
      teamInfoTable: [], // 团队信息数据
      rulesCompanyInfo: {
        companyFullName: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
      },
      enterpriseInfo: { ...defaultEnterpriseInfo },
      members: [],
      companyId: "", // 企业主键
      // isNull: '', // 判断companyKeywordAddParamList是否为空

      // tab类名控制
      headTabActive: this.$route.query.headTabActive,
      // 点击编辑进来的 那一项id
      activeId: this.$route.query.id,
    };
  },
  computed: {
    companyCode() {
      return this.$route.query.companyCode;
    },
    enterpriseType() {
      return Number(this.$route.query.enterpriseType);
    },
    queryInfo() {
      return this.$route.query.queryInfo;
    },
    type() {
      return Number(this.$route.query.type)
        ? Number(this.$route.query.type)
        : Number(this.$route.params.type);
    },
  },

  created() {
    //主键 = 路由带过来的主键
    this.companyId = this.$route.query.companyCode;
    if (this.companyId) {
      this.search();
      this.searchLabel();
      // this.searchMember();
    }
  },

  mounted() {
    document.querySelector(".el-tabs__content").style.minHeight =
      document.body.clientHeight - 368 + "px";
  },

  methods: {
    // 企业电话发生改变
    inputChange(val) {
      this.enterpriseInfo.companyTelephones = val;
    },
    // 企业邮箱发生改变
    inputEmailChange(val) {
      this.enterpriseInfo.companyEmails = val;
    },

    // 查询
    async search() {
      const { data: res } = await getCompanyById({
        companyCode: this.companyCode,
      });

      this.enterpriseInfo = res;

      // 解决编辑页面企业电话或企业邮箱在新增时为空时,输入框不展示
      if (!res.companyEmails || !res.companyTelephones) {
        this.enterpriseInfo.companyEmails = [""];
        this.enterpriseInfo.companyTelephones = [""];
      }

      this.enterpriseInfo.fieldId = this.formatString(
        this.enterpriseInfo.fieldId
      );

      this.enterpriseInfo.techId = this.formatString(
        this.enterpriseInfo.techId
      );

      this.enterpriseInfo.industrialChainId = this.formatString(
        this.enterpriseInfo.industrialChainId
      );
    },
    // 企业标签查询
    async searchLabel() {
      const { data: res } = await selectCompanyLabel({
        companyId: this.companyCode,
      });
      // this.$refs.labelInfo.companyLabelParam = res;
      // 解决企业标签的关键词至标签编号新增时为空时,输入框不展示
      if (!res.companyKeywordAddParamList) {
        this.$refs.labelInfo.companyLabelParam = {
          keywordVersion: "",
          companyKeywordAddParamList: [
            {
              companyLabelList: [
                {
                  groupLabelId: "", // 标签组编号
                  groupLabelName: "", // 标签组名称
                  labelId: "", // 标签编号
                  labelName: "", // 标签内容
                  companyKeywordId: null,
                  superiorLabel: null, // 上级标签
                },
              ],
              keyword: "", // 关键词
              keywordDescribe: "", // 关键词数据
            },
          ],
        };
      } else {
        // 如果标签内容有数据的话就将校验关闭
        // this.$refs.labelInfo.rules.labelName[0].required = false;
        this.$refs.labelInfo.companyLabelParam = res;
        // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
        res.companyKeywordAddParamList.forEach((item) => {
          item.companyLabelList.forEach((child) => {
            child.labelName = child.groupLabelName + "/" + child.labelName;
          });
        });
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    editClick() {
      this.isShowInput = true;
    },
    // 修改公司全称
    async sureInfo() {
      this.$refs.rulesCompanyInfo.validate(async (valid) => {
        if (valid) {
          const res = await updateCompany(this.enterpriseInfo);
          this.isShowInput = false;
        }
      });
    },
    // 新增公司全称和公司logo
    saveCompanyInfo() {
      this.$refs.rulesCompanyInfo.validate(async (valid) => {
        if (valid) {
          const params = {
            companyAddParam: this.enterpriseInfo,
            members: this.members,
          };
          const res = await addCompany(params);

          if (res.code === 200) {
            this.$message.success("新增成功");
          }
        }
      });
    },
    // 企业信息新增时新增团队信息
    addTeamInfo(val) {
      this.$nextTick(() => {
        this.members.push(JSON.parse(JSON.stringify(val)));

        this.teamInfoTable.push(JSON.parse(JSON.stringify(val)));
        this.teamInfoTable.forEach((item) => {
          if (
            item.memberTechId.length &&
            typeof item.memberTechId !== "string"
          ) {
            item.memberTechId = item.memberTechId.join(",");
          }
        });
      });
    },
    // 企业信息新增时编辑团队信息
    updateTeamInfo(index, val) {
      this.$set(this.members, index, val);

      this.$set(this.teamInfoTable, index, val);

      this.teamInfoTable.forEach((item) => {
        if (item.memberTechId.length) {
          item.memberTechId = item.memberTechId.join(",");
        }
      });
    },
    // 企业信息新增时删除团队信息
    deleteTeamInfo(index) {
      this.teamInfoTable.splice(index, 1);

      this.members.splice(index, 1);
    },
    // 全部保存
    saveItem() {
      this.$refs.rulesCompanyInfo.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请输入公司全称");
          // this.$message({
          //   message: "请输入公司全称",
          //   type: "warning",
          // });
        } else {
          Object.assign(this.enterpriseInfo, {
            Authorization: this.Authorization,
          });
          let res;

          // this.$refs.basicInfo.$refs.rulesCompanyInfo.validate(async valid => {
          //   if (valid) {
          //     if (this.enterpriseInfo.fieldChildrenId) {
          //       this.enterpriseInfo.fieldId = this.enterpriseInfo.fieldId.concat(
          //         this.enterpriseInfo.fieldChildrenId
          //       );
          //     }
          //     if (this.enterpriseInfo.techChildrenId) {
          //       this.enterpriseInfo.techId = this.enterpriseInfo.techId.concat(
          //         this.enterpriseInfo.techChildrenId
          //       );
          //     }

          //     this.enterpriseInfo.techId = [...new Set(this.enterpriseInfo.techId)];
          //     this.enterpriseInfo.fieldId = [
          //       ...new Set(this.enterpriseInfo.fieldId)
          //     ];
          //     if (typeof this.members.memberTechId === "string") {
          //       this.members.memberTechId = this.members.memberTechId.split(",");
          //     }
          //     const params = {
          //       companyAddParam: this.enterpriseInfo,
          //       members: this.members
          //     };

          //     if (this.enterpriseType) {
          //       // 编辑
          //       res = await updateCompany(this.enterpriseInfo);
          //     } else {
          //       // 新增
          //       console.log(this.enterpriseType,'this.enterpriseType新增');
          //       res = await addCompany(params);
          //     }

          //     if (res.code === 200) {
          //       // 如果保存成功

          //       this.$message.success(
          //         `${this.enterpriseType ? "编辑" : "新增"}成功`
          //       );

          //       // 跳转到首页
          //       this.$router.push({
          //         name: "Enterprise",
          //         params: {
          //           enterpriseType: 1,
          //           queryInfo: this.queryInfo
          //         }
          //       });
          //     } else {
          //       // 如果保存失败
          //       // this.activeName = false
          //       // this.dialogVisible = true

          //       this.$message.error(res.msg);
          //     }
          //   }
          // });
          const params = {
            companyAddParam: this.enterpriseInfo,
            members: this.members,
          };
          // 如果新增过，就将返回的企业主键id赋值进去
          if (this.companyId) {
            params.companyAddParam.companyCode = this.companyId;
          }
          switch (this.activeName) {
            case "first":
              this.$refs.basicInfo.$refs.rulesCompanyInfo.validate(
                async (valid) => {
                  if (valid) {
                    if (this.enterpriseInfo.fieldChildrenId) {
                      this.enterpriseInfo.fieldId =
                        this.enterpriseInfo.fieldId.concat(
                          this.enterpriseInfo.fieldChildrenId
                        );
                    }
                    if (this.enterpriseInfo.techChildrenId) {
                      this.enterpriseInfo.techId =
                        this.enterpriseInfo.techId.concat(
                          this.enterpriseInfo.techChildrenId
                        );
                    }
                    this.enterpriseInfo.techId = [
                      ...new Set(this.enterpriseInfo.techId),
                    ];
                    this.enterpriseInfo.fieldId = [
                      ...new Set(this.enterpriseInfo.fieldId),
                    ];
                    if (typeof this.members.memberTechId === "string") {
                      this.members.memberTechId =
                        this.members.memberTechId.split(",");
                    }

                    //新增还是保存
                    res = this.companyId
                      ? await updateCompany(this.enterpriseInfo)
                      : await addCompany(params);
                    if (res.code === 200) {
                      this.basicInfo = 1;

                      // 在新增页赋值(companyCode存在的话，说明进入了编辑页面)
                      if (
                        !this.companyCode &&
                        this.companyCode !== "修改成功"
                      ) {
                        this.companyId = res.data;
                      }

                      this.$message.success(
                        `${this.enterpriseType ? "编辑" : "新增"}成功`
                      );

                      this.activeName = "second";
                    } else {
                      this.$message.error(res.msg);
                    }
                  }
                }
              );
              break;
            case "second":
              this.$refs.enterpriseInfo.$refs.enterpriseInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    if (this.enterpriseInfo.fieldChildrenId) {
                      this.enterpriseInfo.fieldId =
                        this.enterpriseInfo.fieldId.concat(
                          this.enterpriseInfo.fieldChildrenId
                        );
                    }
                    if (this.enterpriseInfo.techChildrenId) {
                      this.enterpriseInfo.techId =
                        this.enterpriseInfo.techId.concat(
                          this.enterpriseInfo.techChildrenId
                        );
                    }

                    this.enterpriseInfo.techId = [
                      ...new Set(this.enterpriseInfo.techId),
                    ];
                    this.enterpriseInfo.fieldId = [
                      ...new Set(this.enterpriseInfo.fieldId),
                    ];
                    if (typeof this.members.memberTechId === "string") {
                      this.members.memberTechId =
                        this.members.memberTechId.split(",");
                    }
                    res = this.companyId
                      ? await updateCompany(this.enterpriseInfo)
                      : await addCompany(params);
                    if (res.code === 200) {
                      this.enterpriseInfoPage = 1;

                      if (!this.companyId && this.companyId !== "修改成功") {
                        this.companyId = res.data;
                      }

                      this.$message.success(
                        `${this.companyId ? "编辑" : "新增"}成功`
                      );

                      this.activeName = "third";
                    } else {
                      this.$message.error(res.msg);
                    }
                  }
                }
              );
              break;
            case "third":
              this.$refs.contactInfo.$refs.contactInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    if (this.enterpriseInfo.fieldChildrenId) {
                      this.enterpriseInfo.fieldId =
                        this.enterpriseInfo.fieldId.concat(
                          this.enterpriseInfo.fieldChildrenId
                        );
                    }
                    if (this.enterpriseInfo.techChildrenId) {
                      this.enterpriseInfo.techId =
                        this.enterpriseInfo.techId.concat(
                          this.enterpriseInfo.techChildrenId
                        );
                    }

                    this.enterpriseInfo.techId = [
                      ...new Set(this.enterpriseInfo.techId),
                    ];
                    this.enterpriseInfo.fieldId = [
                      ...new Set(this.enterpriseInfo.fieldId),
                    ];
                    if (typeof this.members.memberTechId === "string") {
                      this.members.memberTechId =
                        this.members.memberTechId.split(",");
                    }

                    res = this.companyId
                      ? await updateCompany(this.enterpriseInfo)
                      : await addCompany(params);

                    if (res.code === 200) {
                      this.contactInfo = 1;

                      if (!this.companyId && this.companyId !== "修改成功") {
                        this.companyId = res.data;
                      }

                      this.$message.success(
                        `${this.companyId ? "编辑" : "新增"}成功`
                      );

                      this.activeName = "fourth";
                    } else {
                      this.$message.error(res.msg);
                    }
                  }
                }
              );
              break;
            case "fourth":
              if (!this.companyId) {
                this.$message({
                  showClose: true,
                  message: "请先保存上个页面的数据再进行下一步操作",
                  type: "error",
                });
              } else {
                // if (this.$refs.teamInfo.dialogVisible) {
                //     this.$refs.teamInfo.$refs.teamInfoObj.validate(async valid => {
                //       if (valid) {
                //         if (this.enterpriseInfo.fieldChildrenId) {
                //           this.enterpriseInfo.fieldId = this.enterpriseInfo.fieldId.concat(
                //             this.enterpriseInfo.fieldChildrenId
                //           );
                //         }
                //         if (this.enterpriseInfo.techChildrenId) {
                //           this.enterpriseInfo.techId = this.enterpriseInfo.techId.concat(
                //             this.enterpriseInfo.techChildrenId
                //           );
                //         }

                //         this.enterpriseInfo.techId = [...new Set(this.enterpriseInfo.techId)];
                //         this.enterpriseInfo.fieldId = [
                //           ...new Set(this.enterpriseInfo.fieldId)
                //         ];
                //         if (typeof this.members.memberTechId === "string") {
                //           this.members.memberTechId = this.members.memberTechId.split(",");
                //         }

                //         res = this.companyId ? await updateMember(params) : await updateMember(params);

                //         // if (this.enterpriseType) {
                //         //   // 编辑
                //         //   res = await updateCompany(this.enterpriseInfo);
                //         // } else {
                //         //   // 新增
                //         //   res = await addCompany(params);
                //         //   this.enterpriseType = 1;
                //         // }

                //         if (res.code === 200) {
                //           this.teamInfo = 1

                //           this.$message.success(
                //             `${this.companyId ? "编辑" : "新增"}成功`
                //           );

                //           this.activeName = 'five'
                //         } else {
                //           this.$message.error(res.msg);
                //         }
                //       }
                //     })
                // } else {
                //     this.$refs.teamInfo.$refs.teamInfoObj.validate(async valid => {
                //       if (valid) {
                //         if (this.enterpriseInfo.fieldChildrenId) {
                //           this.enterpriseInfo.fieldId = this.enterpriseInfo.fieldId.concat(
                //             this.enterpriseInfo.fieldChildrenId
                //           );
                //         }
                //         if (this.enterpriseInfo.techChildrenId) {
                //           this.enterpriseInfo.techId = this.enterpriseInfo.techId.concat(
                //             this.enterpriseInfo.techChildrenId
                //           );
                //         }

                //         this.enterpriseInfo.techId = [...new Set(this.enterpriseInfo.techId)];
                //         this.enterpriseInfo.fieldId = [
                //           ...new Set(this.enterpriseInfo.fieldId)
                //         ];
                //         if (typeof this.members.memberTechId === "string") {
                //           this.members.memberTechId = this.members.memberTechId.split(",");
                //         }
                //         res = this.companyId ? await updateMember(params) : await updateMember(params);
                //         if (res.code === 200) {
                //           this.teamInfo = 1

                //           this.$message.success(
                //             `${this.companyId ? "编辑" : "新增"}成功`
                //           );

                //           this.activeName = 'five'
                //         } else {
                //           this.$message.error(res.msg);
                //         }
                //       }
                //     })
                // }
                this.activeName = "five";
              }
              break;
            case "five":
              //最终组装的数组
              let arr = [];
              //开始添加
              this.$refs.labelInfo.companyLabelParam.companyKeywordAddParamList.forEach(
                (item) => {
                  let keyword = item.keyword;
                  let keywordDescribe = item.keywordDescribe;
                  let companyLabelList = [];
                  // 遍历我添加的数组
                  item.companyLabelList.forEach((child) => {
                    // 遍历总数组
                    this.$refs.labelInfo.labelOptions.forEach((item1) => {
                      if (item1.labelKeywordResultList[0].id == child.labelId) {
                        item1.labelKeywordResultList.forEach((name) => {
                          name = {
                            groupLabelId: name.groupLabelPrefix,
                            groupLabelName: name.groupLabelType,
                            labelId: name.id,
                            labelName: name.labelName,
                            labelStatus:
                              item1.labelKeywordResultList[0].id == name.id
                                ? 1
                                : 0,
                          };
                          companyLabelList.push(name);
                        });
                      }
                    });
                  });

                  let all = {
                    keyword: keyword,
                    keywordDescribe: keywordDescribe,
                    companyLabelList: companyLabelList,
                  };
                  arr.push(all);
                }
              );

              // 最终提交后端的数据
              let companyLabelParam = {
                keywordVersion:
                  this.$refs.labelInfo.companyLabelParam.keywordVersion, //关键字版本
                companyId: this.companyId, //产品主键
                companyKeywordAddParamList: arr,
                type: this.type,
              };

              if (!this.companyId) {
                this.$message({
                  showClose: true,
                  message: "请先保存上个页面的数据再进行下一步操作",
                  type: "error",
                });
              } else {
                this.$refs.labelInfo.$refs.labelInfoForm.validate(
                  async (valid) => {
                    if (valid) {
                      this.$refs.labelInfo.companyLabelParam.companyId =
                        this.companyId;
                      this.$refs.labelInfo.companyLabelParam.type = this.type;

                      //判断dd里面返回的3个参数为不为空 如果3个都为空则调用增加 否则则修改
                      if (this.enterpriseType) {
                        // 编辑
                        if (this.$refs.labelInfo.companyLabelParam) {
                          // res = await updateCompanyLabel(
                          //   this.$refs.labelInfo.companyLabelParam
                          // );
                          res = await updateCompanyLabel(companyLabelParam);
                        } else {
                          // res = await updateCompanyLabel(
                          //   this.$refs.labelInfo.companyLabelParam
                          // );
                          res = await updateCompanyLabel(companyLabelParam);
                        }
                      } else {
                        // 新增
                        res = await insertCompanyLabel(companyLabelParam);
                      }

                      if (res.code == 200) {
                        this.$message.success(
                          `${this.enterpriseType ? "编辑" : "新增"}成功`
                        );

                        this.activeName = "six";

                        // // 跳转到首页
                        // this.$router.push({
                        //   name: "Enterprise",
                        //   params: {
                        //     enterpriseType: 1,
                        //     queryInfo: this.queryInfo,
                        //   },
                        // });
                      } else {
                        this.$message.error(res.msg);
                      }
                    }
                  }
                );
              }
              break;
            case "six":
              // if (res.code == 200) {
              //   this.$message.success(
              //     `${this.enterpriseType ? "编辑" : "新增"}成功`
              //   );

              //   // 跳转到首页
              //   this.$router.push({
              //     name: "Enterprise",
              //     params: {
              //       enterpriseType: 1,
              //       queryInfo: this.queryInfo,
              //     },
              //   });
              // } else {
              //   this.$message.error(res.msg);
              // }
              break;
            default:
              break;
          }
        }
      });
    },
    cancelItem() {
      // this.$router.go(-1);

      this.$router.push({
        name: "Enterprise",
      });
    },
    // 切换tabs
    selectTabs(tab) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company_left {
    display: flex;
    .info {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .fullName {
        display: flex;
        align-items: center;
        .jr-iconjr-icon-edit1:hover {
          color: #4d77ff;
        }
        .el-button {
          margin-left: 10px;
        }
        .companyFullName {
          font-size: 24px;
          color: #363e4d;
        }
        i {
          margin-left: 10px;
        }
      }

      .updateTime {
        color: #7c7f8e;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .company_right {
    font-size: 12px;
    color: #7c7f8e;
  }
}
/deep/ .el-tabs__content {
  overflow: auto;
}
.companyInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    margin-right: 20px;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
