<template>
  <div class="teamInfo">
    <el-button type="primary" icon="el-icon-plus" @click="addTeam">添加团队</el-button>
    <el-table
      class="ly_table"
      :data="teamInfoTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
    >
      <template slot="empty">
        <img src="@/assets/img/table.png" alt="没有数据" srcset />
      </template>
      <el-table-column prop="memberPortroit" label="人物头像" align="center">
        <template v-slot="{ row }">
          <div v-if="row.memberPortroits">
            <el-image :src="row.memberPortroits" class="headerImg" />
          </div>
          <div style="cursor: pointer" v-else>
            <el-image :src="require('../../../assets/img/pro.png')" class="headerImg" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="memberName" label="人物姓名" align="center" />
      <el-table-column prop="memberPosition" label="人物职务" align="center" />
      <el-table-column prop="memberProfile" label="人物介绍" align="center" />
      <el-table-column prop="memberWeibo" label="微博" align="center" />
      <el-table-column prop="memberLinkEdin" label="领英" align="center" />
      <el-table-column prop="memberTechId" label="技术类别" align="center" />
      <el-table-column prop="memberIncumbency" label="是否在职" align="center" />
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <button-table @buttonClick="putItem(scope.row)" content="入库" icon="jr-iconjr-icon-fact1" />
          <button-table
            @buttonClick="editItem(scope.$index,scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
          />
          <button-table
            @buttonClick="deleteItem(scope.$index,scope.row)"
            content="删除"
            icon="jr-iconjr-icon-delect"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      ref="addVisible"
      :title="dialogType ? '编辑团队信息' : '添加团队信息'"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
      center
    >
      <el-form ref="teamInfoObj" :model="teamInfoObj" :rules="teamInfoRules" label-width="100px">
        <el-form-item label="人物头像：" class="avatar">
          <upload-image
            :imageUrl.sync="teamInfoObj.memberPortroits"
            :saveUrl.sync="teamInfoObj.memberPortroit"
            :upLoadFile="upLoadFile"
          />
        </el-form-item>
        <el-form-item label="人物姓名：">
          <el-input v-model="teamInfoObj.memberName" placeholder="请输入人物姓名" />
        </el-form-item>
        <el-form-item label="人物职位：">
          <el-input v-model="teamInfoObj.memberPosition" placeholder="请输入人物职位" />
        </el-form-item>
        <el-form-item label="人物介绍：" prop="memberProfile">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="teamInfoObj.memberProfile"
            placeholder="请输入人物介绍"
          />
        </el-form-item>
        <el-form-item label="微博：">
          <el-input v-model="teamInfoObj.memberWeibo" placeholder="请输入微博" />
        </el-form-item>
        <el-form-item label="领英：">
          <el-input v-model="teamInfoObj.memberLinkEdin" placeholder="请输入领英" />
        </el-form-item>
        <el-form-item label="技术类别：" style="margin-bottom:0px">
          <dic-checkbox-group
            ref="checkboxTeamInfo"
            :code.sync="teamInfoObj.memberTechId"
            tag="技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="是否在职：">
          <el-radio-group v-model="teamInfoObj.memberIncumbency">
            <el-radio
              v-for="item in memberIncumbencyArray"
              :key="item.label"
              :label="item.label"
            >{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  getMemberById,
  addMember,
  updateMember,
  deleteMember,
  saveMember,
} from '@/api/enterprise'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import UploadImage from '@/components/UploadImage.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import { pagination } from '@/mixins/pagination'

export default {
  mixins: [pagination],

  name: 'TeamInfo',

  props: {
    enterpriseInfo: {
      type: Object,
    },
    enterpriseType: {
      type: Number,
    },
    teamInfoTable: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String
    }
  },

  components: {
    ButtonTable,
    UploadImage,
    DicCheckboxGroup,
  },

  data() {
    return {
      dialogType: 0, // 面板类型 0 添加 1 编辑
      dialogVisible: false,
      teamInfoObj: {
        memberName: '',
        memberPosition: '',
        memberProfile: '',
        memberWeibo: '',
        memberLinkEdin: '',
        memberPortroit: '', // 人物头像
        memberIncumbency: '', // 是否在职
        memberTechId: [], // 技术类别
        memberChildrenTechId: [], // 技术类别二级标签
        memberPortroits: '',
      },
      teamInfoRules: {
        memberProfile: [
          { required: true, message: '请输入人物介绍', trigger: 'blur' },
        ],
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
      },
      memberIncumbencyArray: [{ label: '是' }, { label: '否' }],
      upLoadFile: [],
      currentIndex: '',
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },

    companyCode() {
      return this.$route.query.companyCode
    },
  },

  created() {
    this.search()
  },

  methods: {
    updateCode(val, tag) {
      this.teamInfoObj.memberChildrenTechId = val
    },
    // 查询
    async search() {
      Object.assign(this.queryInfo, { companyCode: this.companyId })

      const { data: res } = await getMemberById(this.queryInfo)

      this.$emit('update:teamInfoTable', res.list)

      this.total = res.total
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
    // 添加团队
    addTeam() {
      this.teamInfoObj = {
        memberName: '',
        memberPosition: '',
        memberProfile: '',
        memberWeibo: '',
        memberLinkEdin: '',
        memberPortroit: '', // 人物头像
        memberIncumbency: '', // 是否在职
        memberTechId: [], // 技术类别
        memberChildrenTechId: [],
      }

      this.dialogType = 0

      this.dialogVisible = true
    },
    // 入库
    async putItem(data) {
      const dataOne = data
      if (typeof dataOne.memberTechId === 'string') {
        dataOne.memberTechId = dataOne.memberTechId.split(',')
      }
      const memberOne = {
        memberName: dataOne.memberName,
        memberPortroit: dataOne.memberPortroit,
        memberPosition: dataOne.memberPosition,
        memberProfile: dataOne.memberProfile,
        memberTechId: dataOne.memberTechId,
        memberWeibo: dataOne.memberWeibo,
        Authorization: this.Authorization,
      }
      const res = await saveMember(memberOne)
      if (res.code == 200) {
        this.$message.success('入库成功')
      } else {
        this.$messaage.success('入库失败')
      }
    },
    // 编辑
    editItem(index, row) {
      this.currentIndex = index

      Object.assign(this.teamInfoObj, row)

      this.teamInfoObj.memberTechId = this.formatString(
        this.teamInfoObj.memberTechId
      )

      this.dialogType = 1

      this.dialogVisible = true

      if (this.$refs.checkboxTeamInfo) {
        this.$refs.checkboxTeamInfo.getDicData()
      }
    },
    // 弹窗关闭
    handleClose() {
      this.upLoadFile = []
      this.$refs.checkboxTeamInfo.clear()
      this.dialogVisible = false
    },
    // 编辑和新增
    async submitForm() {
      this.$refs.teamInfoObj.validate(async (valid) => {
        if (this.teamInfoObj.memberChildrenTechId) {
          this.teamInfoObj.memberTechId = this.teamInfoObj.memberTechId.concat(
            this.teamInfoObj.memberChildrenTechId
          )
        }

        if (valid) {
          let res

          // 企业数据编辑
          if (this.companyId) {
            // 团队信息编辑
            if (this.dialogType) {
              // res = await updateMember(this.submitForm)
              res = await updateMember(this.teamInfoObj)
            } else {
              res = await addMember(
                Object.assign(this.teamInfoObj, {
                  companyCode: this.companyId,
                })
              )
            }
            if (res.code === 200) {
              this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)
              this.search()
            }
          } else {

            // 新增企业数据
            // 编辑团队信息
            if (this.dialogType) {
              this.$emit('updateTeamInfo', this.currentIndex, this.teamInfoObj)
            } else {
              console.log(this.teamInfoObj,'this.teamInfoObj');
              this.$emit('addTeamInfo', this.teamInfoObj)
            }
          }
          this.dialogVisible = false
        }
      })
    },
    // 删除
    async deleteItem(index, row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.enterpriseType) {
            const res = await deleteMember({ id: row.id })

            if (res.code === 200) {
              this.$message.success('删除成功')

              this.search()
            }
          } else {
            this.$emit('deleteTeamInfo', index)
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style>
</style>
