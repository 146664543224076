<template>
  <div class="contactInfo">
    <el-form :model="enterpriseInfo" label-width="130px" :rules="rules" ref="contactInfoForm">
      <el-form-item label="微博网址：">
        <el-input v-model="enterpriseInfo.companyWeibo" class="lager-input" />
      </el-form-item>
      <el-form-item label="微信公众号(企业)：">
        <el-input v-model="enterpriseInfo.companyWechat" class="lager-input" />
      </el-form-item>
      <el-form-item label="企业领英地址：">
        <el-input v-model="enterpriseInfo.companyLinkEdin" class="lager-input" />
      </el-form-item>
      <!-- <el-form-item label="公司详细地址：">
        <el-input v-model="enterpriseInfo.companyAddress" class="lager-input" />
      </el-form-item>
      <el-form-item label="公开邮箱：" prop="companyEmail">
        <el-input v-model="enterpriseInfo.companyEmail" class="lager-input" />
      </el-form-item>
      <el-form-item label="公开电话：" prop="companyTelephone">
        <el-input
          v-model="enterpriseInfo.companyTelephone"
          class="lager-input"
          onkeyup="value=value.replace(/[^\d]/g,'')"
        />
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { updateCompany } from '@/api/enterprise'
export default {
  name: 'ContactInfo',

  props: {
    enterpriseInfo: {
      type: Object,
    },
  },
  data() {
    // 验证手机
    let checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }
    return {
      rules: {
        // companyEmail: [
        //   {
        //     validator: checkEmail,
        //     message: '请输入正确的邮箱',
        //     trigger: ['blur'],
        //   },
        // ],
      },
    }
  },
  methods: {
    // 保存
    async saveItem() {
      const res = await updateCompany(this.enterpriseInfo)

      if (res.code === 200) {
        this.$message.success('编辑成功')

        this.$router.go(-1)
      }
    },
    // 取消
    cancelItem() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="less" scoped>
.contactInfo {
  /deep/.el-form-item__label {
    width: 140px !important;
  }
}
</style>
