<template>
  <div class="evaluation">
    <el-form
      :model="enterpriseInfo"
      label-width="130px"
      :rules="rules"
      ref="enterpriseInfoForm"
    >
      <el-form-item label="参保人数：" prop="socialSecurityNumber">
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="10人以下"
          >10人以下</el-radio
        >
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="10-15人"
          >10-15人</el-radio
        >
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="15-30人"
          >15-30人</el-radio
        >
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="30-50人"
          >30-50人</el-radio
        >
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="50-100人"
          >50-100人</el-radio
        >
        <el-radio
          v-model="enterpriseInfo.socialSecurityNumber"
          label="100-150人"
          >100-150人</el-radio
        >
        <el-radio v-model="enterpriseInfo.socialSecurityNumber" label="不明确"
          >不明确</el-radio
        >
      </el-form-item>
      <!-- <el-form-item label="企业规模：" prop="companySize">
        <el-select v-model="enterpriseInfo.companySize" placeholder="请选择" style="width: 156px;">
          <el-option
            v-for="item in companySizeOptions"
            :key="item.codeId"
            :label="item.codeName"
            :value="item.codeName">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="企业规模：" prop="companySize">
        <el-radio v-model="enterpriseInfo.companySize" label="L（大型）"
          >L（大型）</el-radio
        >
        <el-radio v-model="enterpriseInfo.companySize" label="M（中型）"
          >M（中型）</el-radio
        >
        <el-radio v-model="enterpriseInfo.companySize" label="S（小型）"
          >S（小型）</el-radio
        >
        <el-radio v-model="enterpriseInfo.companySize" label="XS（小微）"
          >XS（小微）</el-radio
        >
      </el-form-item>
      <el-form-item label="融资轮次：" prop="companyFinancingRounds">
        <el-radio v-model="enterpriseInfo.companyFinancingRounds" label="未融资"
          >未融资</el-radio
        >
        <el-radio v-model="enterpriseInfo.companyFinancingRounds" label="种子轮"
          >种子轮</el-radio
        >
        <el-radio v-model="enterpriseInfo.companyFinancingRounds" label="天使轮"
          >天使轮</el-radio
        >
        <el-radio
          v-model="enterpriseInfo.companyFinancingRounds"
          label="Pre-A轮"
          >Pre-A轮</el-radio
        >
      </el-form-item>
      <el-form-item label="产业覆盖行业数：" prop="industryCoverNum">
        <el-input
          v-model="enterpriseInfo.industryCoverNum"
          style="width: 205px"
          oninput="value=value.replace(/^0|[^0-9]/g, '')"
        />
      </el-form-item>
      <el-form-item label="服务企业数：" prop="serviceCompanyNum">
        <el-input
          v-model="enterpriseInfo.serviceCompanyNum"
          style="width: 205px"
          oninput="value=value.replace(/^0|[^0-9]/g, '')"
        />
      </el-form-item>
      <el-form-item label="产品案例数：" prop="productCaseNum">
        <el-input
          v-model="enterpriseInfo.productCaseNum"
          style="width: 205px"
          oninput="value=value.replace(/^0|[^0-9]/g, '')"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getCodeByStatus } from "@/api/enterprise";
export default {
  name: "Evaluation",

  props: {
    enterpriseInfo: {
      type: Object,
    },
  },
  data() {
    // 验证手机
    let checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      rules: {
        // companyEmail: [
        //   {
        //     validator: checkEmail,
        //     message: '请输入正确的邮箱',
        //     trigger: ['blur'],
        //   },
        // ],
        // industryCoverNum: [{ message: "产业覆盖行业数只能输入数字", trigger: "blur",validator:}],
        // serviceCompanyNum: [{ message: "服务企业数只能输入数字", trigger: "blur",validator:}],
        // productCaseNum:[{ message: "产品案例数只能输入数字", trigger: "blur",validator:}]
      },
      socialSecurityNumber: "1",
      companySizeOptions: [],
      // value: '', //enterpriseInfo.companySize 企业规模
      socialNumRadio: "", // 参保人数
      cfrRadio: "", // 融资轮次]
    };
  },
  methods: {
    // 保存
    // async saveItem() {
    //   const res = await updateCompany(this.enterpriseInfo)

    //   if (res.code === 200) {
    //     this.$message.success('编辑成功')

    //     this.$router.go(-1)
    //   }
    // },
    // 取消
    // cancelItem() {
    //   this.$router.go(-1)
    // },

    // 企业规模
    async getCodeByStatus() {
      const res = await getCodeByStatus({
        code: "047",
      });
      if (res.code == 200) {
        this.companySizeOptions = res.data;
      }
    },
  },
  created() {
    this.getCodeByStatus();
  },
  updated() {
    // this.enterpriseInfo.socialSecurityNumber = this.socialNumRadio
    // this.enterpriseInfo.companyFinancingRounds = this.cfrRadio
    // this.enterpriseInfo.companySize = this.value
  },
};
</script>

<style lang="less" scoped>
</style>
