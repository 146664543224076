<template>
  <div class="basicInfo">
    <el-form
      v-if="isShow"
      :model="enterpriseInfo"
      ref="rulesCompanyInfo"
      :rules="rulesCompanyInfo"
      label-width="120px"
    >
      <!-- <el-form-item label="运营状态：" prop="companyOperationalStatus">
        <el-radio-group v-model="enterpriseInfo.companyOperationalStatus">
          <el-radio
            v-for="(item, index) in query.companyOperationalStatus"
            :key="index"
            :label="item.label"
          ></el-radio>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item label="企业logo：" prop="companyLogo">
        <upload-image
          :imageUrl.sync="enterpriseInfo.companyLogoLook"
          :saveUrl.sync="enterpriseInfo.companyLogo"
        />
      </el-form-item> -->
      <!-- <el-form-item label="企业全称：" prop="companyFullName">
        <el-input type="textarea" v-model="enterpriseInfo.companyFullName" style="width:700px"></el-input>
      </el-form-item> -->
      <el-form-item label="企业简称：" prop="companyName">
        <el-input
          type="textarea"
          v-model="enterpriseInfo.companyName"
          style="width: 700px"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业官网：" prop="companyWebsite">
        <el-input
          v-model="enterpriseInfo.companyWebsite"
          style="width: 700px"
        />
        <!-- <el-link
          :href="enterpriseInfo.companyWebsite"
          target="_blank"
          :underline="false"
          >{{ enterpriseInfo.companyWebsite }}</el-link
        >
        <i
          class="jr-iconjr-icon-edit1"
          style="margin-left: 24px"
          @click="isLinkShow = true"
        ></i>
        <div class="input_button" v-if="isLinkShow">
          <el-input
            v-model="enterpriseInfo.companyWebsite"
            style="width: 470px"
          />
          <el-button
            type="primary"
            style="height: 40px; margin-left: 24px"
            @click="isLinkShow = false"
            >保存</el-button
          >
        </div> -->
      </el-form-item>
      <el-form-item label="企业法人：" prop="companyLegalPerson">
        <el-input
          v-model="enterpriseInfo.companyLegalPerson"
          class="lager-input"
        />
      </el-form-item>
      <el-form-item
        :label="`企业电话(${index + 1})：`"
        prop="companyTelephones"
        v-for="(item, index) in companyTelephonesArr2"
        :key="index"
      >
        <div style="display: flex">
          <el-input
            v-model="companyTelephonesArr2[index]"
            class="lager-input"
            @change="inputChange"
          />
          <div style="display: flex; font-size: 24px; margin-left: 20px">
            <span
              v-if="index == 0"
              style="color: #4e93fb"
              @click="addCompanyTelephones(item)"
            >
              <i class="el-icon-circle-plus-outline"></i>
            </span>
            <span
              v-if="index > 0"
              style="color: #ff7575"
              @click="deletTel(index)"
            >
              <i class="el-icon-remove-outline"></i>
            </span>
          </div>
        </div>
        <!-- <el-dropdown>
          <span class="el-dropdown-link" style="cursor: pointer">
            <i class="el-icon-circle-plus-outline"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="addCompanyTelephones(item)"
              >添加一条企业电话</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <i
          @click="deletTel(index)"
          class="el-icon-remove-outline"
          v-if="index > 0"
        ></i> -->
      </el-form-item>
      <el-form-item
        :label="`企业邮箱(${idx + 1})：`"
        prop="companyEmails"
        v-for="(item, idx) in companyEmailsArr2"
        :key="item"
      >
        <div style="display: flex">
          <el-input
            v-model="companyEmailsArr2[idx]"
            class="lager-input"
            @change="inputEmailChange"
          />
          <div style="display: flex; font-size: 24px; margin-left: 20px">
            <span
              v-if="idx == 0"
              style="color: #4e93fb"
              @click="addCompanyEmails(item)"
            >
              <i class="el-icon-circle-plus-outline"></i>
            </span>
            <span
              v-if="idx > 0"
              style="color: #ff7575"
              @click="deletEmails(idx)"
            >
              <i class="el-icon-remove-outline"></i>
            </span>
          </div>
        </div>
        <!-- <el-dropdown>
          <span class="el-dropdown-link" style="cursor: pointer">
            <i class="el-icon-circle-plus-outline"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="addCompanyEmails(item)"
              >添加一条企业邮箱</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <i
          @click="deletEmails(index)"
          class="el-icon-remove-outline"
          v-if="index > 0"
        ></i> -->
      </el-form-item>
      <el-form-item label="企业地址：" prop="companyAddress">
        <el-input v-model="enterpriseInfo.companyAddress" class="lager-input" />
      </el-form-item>
      <el-form-item label="成立时间：">
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="enterpriseInfo.companyEstablishTime"
          type="date"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item label="所在地区：">
        <cascader-area
          :country="enterpriseInfo.companyCountry"
          :province="enterpriseInfo.companyProvince"
          :city="enterpriseInfo.companyCity"
          @searchArae="searchArae"
        />
      </el-form-item>
      <el-form-item label="一句话介绍：" prop="companyDescribe">
        <el-input
          type="textarea"
          v-model="enterpriseInfo.companyDescribe"
          style="width: 700px"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业简介：" prop="companyBriefIntroduction">
        <el-input
          type="textarea"
          v-model="enterpriseInfo.companyBriefIntroduction"
          style="width: 700px"
        ></el-input>
      </el-form-item>
      <el-form-item label="经营范围：" prop="companyScope">
        <el-input
          type="textarea"
          v-model="enterpriseInfo.companyScope"
          style="width: 700px"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="一句话介绍：" prop="companyDescribe">
        <el-input
          v-model="enterpriseInfo.companyDescribe"
          :autosize="{ minRows: 2, maxRows: 20 }"
          style="width:700px"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="企业简介：" prop="companyBriefIntroduction" style="width:867px">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
          v-model="enterpriseInfo.companyBriefIntroduction"
          style="width:700px"
        />
      </el-form-item>
      <el-form-item label="经营范围：" prop="companyScope" style="width:867px">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
          v-model="enterpriseInfo.companyScope"
          style="width:700px"
        />
      </el-form-item> -->
      <!-- <el-form-item label="社保人数：" class="radio_common">
        <el-radio-group v-model="enterpriseInfo.socialSecurityNumber">
          <el-radio
            v-for="(item, index) in query.socialSecurityNumberArray"
            :key="index"
            :label="item.label"
          ></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="产业类别：">
        <dic-checkbox-group
          :code.sync="enterpriseInfo.industrialChainId"
          ref="industrial"
          type-code="002"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-checkbox-group
          :code.sync="enterpriseInfo.techId"
          tag="技术"
          ref="techId"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-checkbox-group
          :code.sync="enterpriseInfo.fieldId"
          tag="领域"
          ref="fieldId"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="领域标签：" v-if="enterpriseType">
        <el-input
          v-model="enterpriseInfo.wisdomTag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="行业标签：" v-if="enterpriseType">
        <el-input
          v-model="enterpriseInfo.industryTag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="技术标签：" v-if="enterpriseType">
        <el-input
          v-model="enterpriseInfo.aiTag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="产业链标签：" v-if="enterpriseType">
        <el-input
          v-model="enterpriseInfo.industryeventag"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="关键词：" v-if="enterpriseType">
        <el-input
          v-model="enterpriseInfo.antistop"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="合作状态：">
        <dic-radio-group :code.sync="enterpriseInfo.cooperationStatus" type-code="001" />
      </el-form-item> -->
      <!-- <el-form-item label="融资轮次：" class="radio_common">
        <dic-radio-group
          :code.sync="enterpriseInfo.companyFinancingRounds"
          type-code="017"
        />
      </el-form-item>
      <el-form-item label="千里马公司：">
        <el-radio-group v-model="enterpriseInfo.isQianlima">
          <el-radio
            v-for="(item, index) in query.judgeArray"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="独角兽公司：">
        <el-radio-group v-model="enterpriseInfo.isUnicorn">
          <el-radio
            v-for="(item, index) in query.judgeArray"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import CascaderArea from "@/components/CascaderAreaEnter.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import UploadImage from "@/components/UploadImage.vue";
import Editorbar from "@/components/Editorbar";
export default {
  name: "BasicInfo",

  components: {
    DicRadioGroup,
    DicCheckboxGroup,
    CascaderArea,
    UploadImage,
    Editorbar,
  },

  props: {
    enterpriseInfo: {
      type: Object,
    },
    enterpriseType: {
      type: Number,
    },
  },

  data() {
    return {
      isLinkShow: false, //企业链接显示
      isShow: false,
      query: {
        judgeArray: [
          { label: "是", value: 1 },
          { label: "否", value: 0 },
        ],
        companyOperationalStatus: [
          {
            label: "运营中",
          },
          {
            label: "已关闭",
          },
          {
            label: "已转型",
          },
        ],
        socialSecurityNumberArray: [
          {
            label: "10人以下",
          },
          {
            label: "10-15人",
          },
          {
            label: "15-30人",
          },
          {
            label: "30-50人",
          },
          {
            label: "50-100人",
          },
          {
            label: "100-150人",
          },
          {
            label: "150-500人",
          },
          {
            label: "500-1000人",
          },
          {
            label: "1000-2000人",
          },
          {
            label: "2000人以上",
          },
          {
            label: "不明确",
          },
        ],
      },
      rulesCompanyInfo: {
        companyLogo: [
          {
            required: true,
            message: "请上传企业logo",
            trigger: "blur",
          },
        ],
        companyFullName: [
          { required: true, message: "请输入企业全称", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入企业简称", trigger: "blur" },
        ],

        // companyDescribe: [
        //   { required: true, message: "请输入一句话介绍", trigger: "blur" }
        // ],
        // companyBriefIntroduction: [
        //   { required: true, message: "请输入公司简介", trigger: "blur" }
        // ],
        // companyOperationalStatus: [
        //   { required: true, message: "请填写运营状态", trigger: "blur" }
        // ],
      },
      list: {
        countrys: [],
        provinces: [],
        citys: [],
      },
      companyTelephonesArr1: [],
      companyTelephonesArr2: [""],
      companyEmailsArr1: [],
      companyEmailsArr2: [""],
      isClickAddTel: false,
      isClickAddEmails: false,
      showIcon: false,
    };
  },
  watch: {
    enterpriseInfo: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.companyTelephonesArr2 = newVal.companyTelephones;
          this.companyEmailsArr2 = newVal.companyEmails;
        }
      },
      deep: true,
    },
    "enterpriseInfo.companyCode": {
      handler(newVal) {
        if (this.enterpriseType && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 企业电话改变
    inputChange() {
      this.$emit("inputChange", this.companyTelephonesArr2);
    },
    // 企业邮箱改变
    inputEmailChange() {
      this.$emit("inputEmailChange", this.companyEmailsArr2);
    },
    searchArae(val) {
      this.enterpriseInfo.companyCountry = val.country;

      this.enterpriseInfo.companyProvince = val.province;

      this.enterpriseInfo.companyCity = val.city;
    },

    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.enterpriseInfo.fieldChildrenId = val;
          break;

        case "技术":
          this.enterpriseInfo.techChildrenId = val;
          break;

        default:
          break;
      }
    },
    // 添加企业电话
    addCompanyTelephones() {
      this.isClickAddTel = true;
      let length = this.companyTelephonesArr2.length;
      this.companyTelephonesArr2.push("");
      if (length > 2) {
        this.$message("最多只能添加三条企业电话");
        this.companyTelephonesArr2.pop(length - 1);
      }
    },
    deletEmails(index) {
      this.companyEmailsArr2.splice(index, 1);
    },
    // 添加企业邮箱
    addCompanyEmails() {
      this.isClickAddEmails = true;
      let length = this.companyEmailsArr2.length;
      this.companyEmailsArr2.push("");
      if (length > 2) {
        this.$message("最多只能添加三条企业邮箱");
        this.companyEmailsArr2.pop(length - 1);
      }
    },
    deletTel(index) {
      this.companyTelephonesArr2.splice(index, 1);
    },
  },
  async created() {
    // 编辑
    if (!this.enterpriseType) {
      this.isShow = true;
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-textarea__inner {
  letter-spacing: 2px;
}
/deep/ .el-upload {
  background: #dbdbdb;
}
/deep/ .el-upload--picture-card {
  width: 135px;
  height: 92px;
  line-height: 92px;
  top: 126px;
  left: 157px;
  border-radius: 2px;
  background: rgba(237, 237, 237, 1);
  // border: 2px solid rgba(182, 182, 180, 1)
  border: none;
}
.hide {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
.el-icon-circle-plus {
  font-size: 18px;
  color: rgba(30, 159, 255, 1);
  cursor: pointer;
  margin-left: 4px;
}
/deep/ .el-upload--picture-card {
  width: 135px;
  height: 92px;
  line-height: 92px;
}
.editorbar_box {
  width: 700px;
  height: 184px;
  border-radius: 4px;
  border: 1px;
  .editBar {
    width: 700px;
    height: 184px;
  }
  .editor {
    .text {
      max-height: 184px !important;
    }
  }
}
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
</style>
